/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

// styles
import "./src/components/styles/theme.scss"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
    </>
  )
}


