exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-community-page-js": () => import("./../../../src/templates/community-page.js" /* webpackChunkName: "component---src-templates-community-page-js" */),
  "component---src-templates-design-copilot-js": () => import("./../../../src/templates/design-copilot.js" /* webpackChunkName: "component---src-templates-design-copilot-js" */),
  "component---src-templates-designer-page-js": () => import("./../../../src/templates/designer-page.js" /* webpackChunkName: "component---src-templates-designer-page-js" */),
  "component---src-templates-developer-page-js": () => import("./../../../src/templates/developer-page.js" /* webpackChunkName: "component---src-templates-developer-page-js" */),
  "component---src-templates-features-page-js": () => import("./../../../src/templates/features-page.js" /* webpackChunkName: "component---src-templates-features-page-js" */),
  "component---src-templates-hatch-day-page-js": () => import("./../../../src/templates/hatch-day-page.js" /* webpackChunkName: "component---src-templates-hatch-day-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-microsoft-for-startups-js": () => import("./../../../src/templates/microsoft-for-startups.js" /* webpackChunkName: "component---src-templates-microsoft-for-startups-js" */),
  "component---src-templates-opensource-page-js": () => import("./../../../src/templates/opensource-page.js" /* webpackChunkName: "component---src-templates-opensource-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

